import { IconButton } from '@mui/material';
import api from 'api';
import { ActivityType } from 'api/schema';
import { Activity } from 'api/Serializers/Activities';
import { Availability } from 'api/Serializers/Availability';
import { BookingSource } from 'api/Serializers/Cart';
import { FacilityDetailSerializer } from 'api/Serializers/Facilities';
import { InstructorDetailSerializer } from 'api/Serializers/Instructors';
import { InstructorReview } from 'api/Serializers/Reviews';
import Avatar from 'components/avatar';
import Button from 'components/button';
import Callout from 'components/callout';
import Card from 'components/card';
import FavouriteButton from 'components/favourite-button';
import Loading from 'components/loading';
import Modal from 'components/modal';
import {
  DATE_FMT,
  DAYS_PER_AVAILABILITY_PAGE,
  FETCH_STATE,
  RESOLVED_FETCH_STATES,
} from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useFirstRender } from 'hooks/useFirstRender';
import {
  ArrowForwardIcon,
  BackIcon,
  DownIcon,
  InfoIcon,
  MessageIcon,
  UpIcon,
} from 'icons';
import moment from 'moment-timezone';
import { InstructorInfo } from 'pages/locations/instructor-detail';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getCartAppointmentProduct,
  getCartAvailability,
  getSearchAppointmentProduct,
  getSearchAppointmentProductsFetchState,
  getSearchApptProdAvailability,
  getSearchApptProdAvailabilityFetchState,
  getSearchPreferences,
  hasAppliedSearchPreferences,
} from 'state/selectors';
import {
  addAvailabilityToCart,
  setCartAppointmentProduct,
} from 'state/slice/cart';
import {
  fetchApptProdAvailability,
  fetchApptProdAvailabilityNextPage,
  fetchSearchAppointmentProduct,
} from 'state/slice/search';
import { APP_ROUTES, SHARED_ROUTES } from 'utils/routing';

interface BaseAvailabilitySelectProps {
  appointmentProductId: string;
  source: BookingSource;
  manageAvailabilityFetching?: boolean;
  noTimesCTA?: 'favourite' | 'messageInstructor' | null;
  truncateDates?: boolean;
  collapseTimes?: boolean;
  highlightAvailabilityInCart?: boolean;
}

const AvailabilityLoading = () => (
  <div className="grid grid-cols-3 gap-2">
    <div className="animate-pulse">
      <div className="flex flex-col items-center p-4 mb-1 space-y-1 bg-gray-200">
        <div className="w-16 h-4 bg-gray-500 rounded-lg" />
        <div className="w-24 h-4 bg-gray-500 rounded-lg" />
      </div>
      <div className="space-y-2 rounded-md pb-8 p-0.5 flex flex-col">
        {[0, 1].map((i, k) => (
          <div key={`load-${k}`} className={`flex-1 flex justify-center`}>
            <div className="w-16 h-6 bg-blue-300 rounded-md" />
          </div>
        ))}
      </div>
    </div>
    <div className="animate-pulse">
      <div className="flex flex-col items-center p-4 mb-1 space-y-1 bg-gray-200">
        <div className="w-16 h-4 bg-gray-500 rounded-lg" />
        <div className="w-24 h-4 bg-gray-500 rounded-lg" />
      </div>
      <div className="space-y-2 rounded-md pb-8 p-0.5 flex flex-col">
        {[0, 1, 2, 3].map((i, k) => (
          <div key={`load-${k}`} className={`flex-1 flex justify-center`}>
            <div className={`w-16 h-6 bg-blue-300 rounded-md`} />
          </div>
        ))}
      </div>
    </div>
    <div className="animate-pulse">
      <div className="flex flex-col items-center p-4 mb-1 space-y-1 bg-gray-200">
        <div className="w-16 h-4 bg-gray-500 rounded-lg" />
        <div className="w-24 h-4 bg-gray-500 rounded-lg" />
      </div>
      <div className="space-y-2 rounded-md pb-8 p-0.5 flex flex-col">
        {[0, 1, 2].map((i, k) => (
          <div key={`load-${k}`} className={`flex-1 flex justify-center`}>
            <div className="w-16 h-6 bg-blue-300 rounded-md" />
          </div>
        ))}
      </div>
    </div>
  </div>
);

const AvailabilitySelect = ({
  appointmentProductId,
  source,
  manageAvailabilityFetching = true, // Fetch on init and preference changes
  noTimesCTA = 'favourite',
  truncateDates = false,
  collapseTimes = false,
  highlightAvailabilityInCart = true,
}: BaseAvailabilitySelectProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const appointmentProduct = useSelector(
    getSearchAppointmentProduct(appointmentProductId)
  );
  const appointmentProductFetchState = useSelector(
    getSearchAppointmentProductsFetchState
  );
  const availability = useSelector(
    getSearchApptProdAvailability(appointmentProductId)
  );
  const availabilityFetchState = useSelector(
    getSearchApptProdAvailabilityFetchState(appointmentProductId)
  );
  const cartAppointmentProduct = useSelector(getCartAppointmentProduct);
  const cartAvailability = useSelector(getCartAvailability);
  const searchPreferences = useSelector(getSearchPreferences);
  const hasAppliedPreferences = useSelector(hasAppliedSearchPreferences);
  const isFirstRender = useFirstRender();
  const [showTimes, setShowTimes] = useState(!collapseTimes);
  const [isRefreshing, setIsRefreshing] = useState(true);
  const [page, setPage] = useState(1);
  const [availabilityDays, setAvailabilityDays] = useState<
    [string, Availability[]][]
  >([]);
  const [showCartAvailability, setShowCartAvailability] = useState<boolean>(
    highlightAvailabilityInCart
  );
  const didError = [
    appointmentProductFetchState,
    availabilityFetchState,
  ].includes(FETCH_STATE.FAILED);
  const totalDayPages = Math.ceil(availabilityDays.length / 3);
  const fullDayPages = Math.floor(availabilityDays.length / 3);
  const canFetchMore =
    availability !== undefined &&
    appointmentProduct !== undefined &&
    availability.length > 0 &&
    moment(availability[availability.length - 1].date).isBefore(
      moment(appointmentProduct.lastAvailable)
    );
  const onLastPage =
    availabilityFetchState === FETCH_STATE.FULFILLED &&
    page === totalDayPages &&
    !canFetchMore;
  const isFetching = isRefreshing || availabilityFetchState === FETCH_STATE.GET;

  useEffect(() => {
    if (
      !manageAvailabilityFetching ||
      (isFirstRender && availabilityFetchState !== FETCH_STATE.PRISTINE)
    ) {
      // Use cached availability if available
      return;
    }
    setIsRefreshing(true);
    dispatch(fetchApptProdAvailability(appointmentProductId));
  }, [searchPreferences]);

  // Availability postfetch hook
  useEffect(() => {
    if (!RESOLVED_FETCH_STATES.includes(availabilityFetchState)) {
      return;
    }
    if (availabilityFetchState === FETCH_STATE.FULFILLED) {
      const dayData = Object.entries<Availability[]>(
        availability
          .filter((a) => a.isBookable ?? true)
          .groupBy((avail) => avail.date)
      );
      setAvailabilityDays(dayData);
      if (isRefreshing) {
        if (
          highlightAvailabilityInCart &&
          cartAvailability !== undefined &&
          appointmentProductId === cartAppointmentProduct?.id
        ) {
          // If there's already availability in the cart with the same
          // appointment product, we need to open to the page that it's on
          if (
            availability.length > 0 &&
            moment(availability[availability.length - 1].date).isBefore(
              moment(cartAvailability.date)
            ) &&
            canFetchMore
          ) {
            // We didn't fetch far enough into the future -- fetch another page
            dispatch(fetchApptProdAvailabilityNextPage(appointmentProductId));
            return;
          }
          const includesCartAvail = !!availability.find(
            (elt) => elt.id === cartAvailability.id
          );
          const newLastFullPage = Math.floor(dayData.length / 3);
          const dayIndex = includesCartAvail
            ? dayData.findIndex((elt) => elt[0] === cartAvailability.date)
            : 0;
          const newPage = Math.ceil(
            (dayIndex + 1) / DAYS_PER_AVAILABILITY_PAGE
          );
          if (newLastFullPage < newPage && canFetchMore) {
            // Fetch more availability if the new page is the last loaded page
            dispatch(fetchApptProdAvailabilityNextPage(appointmentProductId));
            return;
          }
          if (includesCartAvail) {
            setShowCartAvailability(true);
            setPage(newPage);
          } else {
            setShowCartAvailability(false);
            setPage(1);
          }
        } else if (page !== 1) {
          setPage(1);
        }
      }
    } else {
      setAvailabilityDays([]);
    }
    setIsRefreshing(false);
  }, [availabilityFetchState]);

  useEffect(() => {
    if (!isRefreshing && fullDayPages < page && canFetchMore) {
      dispatch(fetchApptProdAvailabilityNextPage(appointmentProductId));
    }
  }, [page]);

  const handleTimeClick = (availableTime: Availability) => {
    if (appointmentProduct.id !== cartAppointmentProduct?.id)
      dispatch(setCartAppointmentProduct(appointmentProduct));
    if (availableTime.id !== cartAvailability?.id) {
      dispatch(addAvailabilityToCart(availableTime, 'AVAILABILITY', source));
    }
    history.push(
      APP_ROUTES.BOOK.nav(appointmentProductId, 'participants') +
        history.location.search
    );
  };

  const handleClickBack = () => {
    rudderanalytics.track('View more times', { action: 'Back' });
    setPage(page - 1);
  };

  const handleClickNext = () => {
    rudderanalytics.track('View more times', { action: 'Next' });
    setPage(page + 1);
  };

  if (didError) {
    return (
      <div className="flex justify-center">
        <Callout
          className="w-full"
          title="Error loading instructor availability"
          type="error"
        >
          Please refresh to try again
        </Callout>
      </div>
    );
  }

  return (
    <div className="w-full space-y-4">
      <div className="w-full space-y-2">
        {isFetching || availability.length > 0 ? (
          <div className="relative flex items-center justify-between pb-2 cursor-default">
            <button
              id="avail-prev-page"
              onClick={handleClickBack}
              className="btn-icon blue"
              disabled={isFetching || page === 1}
            >
              <BackIcon width={24} />
            </button>
            <button
              id="avail-next-page"
              onClick={handleClickNext}
              className="btn-icon blue"
              disabled={isFetching || onLastPage}
            >
              <ArrowForwardIcon width={24} />
            </button>
          </div>
        ) : (
          <div>
            <h3 className="mb-1">
              {!hasAppliedPreferences
                ? `${appointmentProduct.instructor.firstName}'s schedule is full`
                : 'No times found'}
            </h3>
            {noTimesCTA !== null && (
              <>
                <p className="mb-4">
                  {noTimesCTA === 'messageInstructor'
                    ? `Send ${appointmentProduct.instructor.firstName} a message to inquire about upcoming availability`
                    : `Add ${appointmentProduct.instructor.firstName} to your favourites to get notified about new times`}
                </p>
                <div className="flex justify-center">
                  {noTimesCTA === 'messageInstructor' ? (
                    <Button
                      variant="outlined"
                      color="primary"
                      to={SHARED_ROUTES.MESSAGES.nav(
                        appointmentProduct.instructor.messageId
                      )}
                      icon={<MessageIcon width={24} />}
                    >
                      Message {appointmentProduct.instructor.firstName}
                    </Button>
                  ) : (
                    <FavouriteButton
                      contentObject={appointmentProduct.instructor}
                      contentType="instructor"
                    />
                  )}
                </div>
              </>
            )}
          </div>
        )}
        {isFetching ? (
          <AvailabilityLoading />
        ) : (
          <div className="flex gap-2">
            {availabilityDays.length > 0 && (
              <div className="w-full grid grid-cols-3 gap-2">
                {availabilityDays
                  .slice(
                    DAYS_PER_AVAILABILITY_PAGE * (page - 1),
                    DAYS_PER_AVAILABILITY_PAGE * page
                  )
                  .map((dateData, i) => {
                    const date = dateData[0];
                    const times = dateData[1];
                    return (
                      <div key={date} className="">
                        <div className="p-3 mb-1 text-sm text-center bg-gray-200 rounded-t-xl">
                          <div className="font-semibold text-gray-900">
                            {moment(date).format(
                              truncateDates
                                ? DATE_FMT.DOW_SHORT
                                : DATE_FMT.DOW_FULL
                            )}
                          </div>
                          <div className="text-gray-800 ">
                            {moment(date).format(
                              truncateDates ? DATE_FMT.MON_D : DATE_FMT.MONTH_D
                            )}
                          </div>
                        </div>
                        {showTimes && (
                          <div className="space-y-2 rounded-md p-0.5">
                            {times.map((avail, k) => {
                              const active = cartAvailability?.id === avail.id;
                              return (
                                <button
                                  key={avail.start}
                                  className={`${
                                    showCartAvailability && active
                                      ? 'text-white bg-blue-500 hover:text-blue-100'
                                      : 'text-gray-900 bg-white hover:text-blue-500 hover:border-blue-400 hover:shadow-sm'
                                  } w-full py-2 text-base font-medium transition-all duration-150 border-2 border-transparent rounded-md flex-center`}
                                  onClick={(evt) => handleTimeClick(avail)}
                                >
                                  <div className="">
                                    {moment(avail.start)
                                      .tz(appointmentProduct.timezone)
                                      .format(DATE_FMT.TIME_A)}
                                  </div>
                                </button>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        )}
        {showTimes &&
          showCartAvailability &&
          !isRefreshing &&
          availabilityFetchState === FETCH_STATE.FULFILLED &&
          appointmentProduct.id === cartAppointmentProduct?.id &&
          cartAvailability !== undefined && (
            <Button
              color="primary"
              variant="contained"
              fullWidth={true}
              onClick={() =>
                history.push(
                  APP_ROUTES.BOOK.nav(appointmentProductId, 'participants')
                )
              }
            >
              Continue Booking
            </Button>
          )}
        {!isFetching && collapseTimes && (
          <div className="flex pt-2 justify-center">
            <button
              className="flex link justify-center gap-1"
              onClick={() => setShowTimes((x) => !x)}
            >
              {showTimes ? 'Hide' : 'Show'} times
              {showTimes ? <UpIcon width={14} /> : <DownIcon width={14} />}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export const AvailabilitySelectCard = ({
  appointmentProductId,
  source,
  manageAvailabilityFetching = true, // Fetch on init and preference changes
  noTimesCTA = 'favourite',
  showDetailLink = true,
}: BaseAvailabilitySelectProps & {
  showDetailLink?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const appointmentProduct = useSelector(
    getSearchAppointmentProduct(appointmentProductId)
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [detailData, setDetailData] = useState<{
    activity: Activity;
    instructor: InstructorDetailSerializer;
    facility: FacilityDetailSerializer;
    reviews: InstructorReview[];
  }>();
  const [detailDataFetchState, setDetailDataFetchState] = useState<FETCH_STATE>(
    FETCH_STATE.PRISTINE
  );

  useEffect(() => {
    if (appointmentProduct === undefined) {
      dispatch(fetchSearchAppointmentProduct(appointmentProductId));
    }
  }, []);

  const fetchInstructorDetailData = async (facilitySlug, instructorSlug) => {
    setDetailDataFetchState(FETCH_STATE.GET);
    setIsModalOpen(true);
    try {
      const responses = await Promise.all([
        api.activities.retrieve(ActivityType.PrivateSwimLessons),
        api.facilities.detail(facilitySlug),
        api.instructors.retrieve(instructorSlug, { facility: facilitySlug }),
        api.instructors.reviews(instructorSlug),
      ]);
      setDetailData({
        activity: responses[0].data.activity,
        facility: responses[1].data,
        instructor: responses[2].data,
        reviews: responses[3].data,
      });
      setDetailDataFetchState(FETCH_STATE.FULFILLED);
    } catch (err) {
      setDetailDataFetchState(FETCH_STATE.FAILED);
    }
  };

  return (
    <>
      <Card className="flex flex-col w-full gap-4" maxWidth="lg">
        {appointmentProduct !== undefined && (
          <div className="flex items-center gap-4">
            <div className="flex h-24 shrink-0">
              <img
                className="object-cover w-32 rounded-lg"
                src={appointmentProduct.facility.hero}
              />
              <Avatar
                diameter={24}
                className="-ml-8"
                border
                src={appointmentProduct.instructor.avatar}
              />
            </div>
            <div className="flex flex-col gap-0.5 grow">
              <span className="flex items-center gap-1 text-lg font-semibold">
                {appointmentProduct.instructor.displayName}
                {showDetailLink && (
                  <IconButton
                    onClick={() => {
                      fetchInstructorDetailData(
                        appointmentProduct.facility.slug,
                        appointmentProduct.instructor.slug
                      );
                    }}
                    size="small"
                  >
                    <InfoIcon width={24} />
                  </IconButton>
                )}
              </span>
              <span>
                {appointmentProduct.facility.displayName} &#8211;{' '}
                {appointmentProduct.distance}km
              </span>
              <span>
                ${appointmentProduct.price} &#8211;{' '}
                {appointmentProduct.numAvailable ?? 0} spots
              </span>
            </div>
          </div>
        )}
        <AvailabilitySelect
          appointmentProductId={appointmentProductId}
          manageAvailabilityFetching={manageAvailabilityFetching}
          noTimesCTA={noTimesCTA}
          source={source}
        />
      </Card>
      {showDetailLink && (
        <Modal
          name="About the instructor"
          title="About the instructor"
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          maxWidth="md"
        >
          <div>
            {detailDataFetchState === FETCH_STATE.GET ? (
              <div className="flex justify-center">
                <Loading
                  position="inline-contained"
                  message="Getting instructor data..."
                />
              </div>
            ) : detailDataFetchState === FETCH_STATE.FAILED ? (
              <Callout type="error">
                Error getting instructor info. Please refresh to try again.
              </Callout>
            ) : detailDataFetchState === FETCH_STATE.FULFILLED ? (
              <InstructorInfo
                activity={detailData.activity}
                instructor={detailData.instructor}
                facility={detailData.facility}
                reviews={detailData.reviews}
              />
            ) : null}
          </div>
        </Modal>
      )}
    </>
  );
};

export default AvailabilitySelect;
