import InstructorAccount from 'api/Serializers/Accounts/Instructor';
import { InstructorReliabilityHistory } from 'api/Serializers/Analytics';
import { AppointmentListSerializer } from 'api/Serializers/Appointments';
import Dashboard from 'components/account/dashboard';
import Button from 'components/button';
import Callout from 'components/callout';
import CardActions from 'components/card-actions';
import Controls from 'components/controls';
import Link from 'components/link';
import Loading from 'components/loading';
import Modal from 'components/modal';
import {
  DATE_FMT,
  FETCH_STATE,
  INSTRUCTOR_LATE_CANCELLATION_RATE_AVG,
  INSTRUCTOR_LATE_CANCELLATION_RATE_ELITE,
  INSTRUCTOR_LATE_CANCELLATION_RATE_MAX,
  INSTRUCTOR_OVERALL_CANCELLATION_RATE_AVG,
  INSTRUCTOR_OVERALL_CANCELLATION_RATE_ELITE,
  INSTRUCTOR_OVERALL_CANCELLATION_RATE_MAX,
  QueryParams,
  RECENT_CANCELLATION_NUM_DAYS,
} from 'config';
import { ScheduleObject } from 'features/schedule/as-instructor/bookings';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { HelpIcon } from 'icons';
import moment from 'moment-timezone';
import { INSTRUCTOR_ROUTES } from 'pages/account/instructor/utils';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getAccountDetail,
  getAccountEarnings,
  getAccountEarningsFetchState,
  getAccountReliability,
  getEarlyAccessWindows,
  getInstructorOnboarding,
  getUpcomingAppointments,
} from 'state/selectors';
import { fetchEarlyAccessWindows } from 'state/slice/account';
import { fetchNextAppointments } from 'state/slice/appointments';
import { LocalStore } from 'state/storage';
import { isTaxSeason } from 'utils/date';
import { EXTERNAL_ROUTES, SHARED_ROUTES, SHOW_HELP } from 'utils/routing';

type ModalType =
  | 'Cancellation rate'
  | 'Hourly earnings'
  | 'Equipment bonus'
  | 'Repeat clients';

const Overview = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [futureDates, setFutureDates] = useState<
    [string, AppointmentListSerializer[]][]
  >([]);
  const dispatch = useAppDispatch();
  const appointments = useSelector(getUpcomingAppointments);
  const earlyAccessWindows = useSelector(getEarlyAccessWindows);
  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      dispatch(fetchNextAppointments()),
      dispatch(fetchEarlyAccessWindows()),
    ]).then((responses) => {
      setIsLoading(false);
    });
  }, []);
  useEffect(() => {
    const today = moment().format(DATE_FMT.DATE_KEY);
    setFutureDates(
      Object.entries(
        appointments
          .filter((apt) => !apt.cancelled && apt.date >= today)
          .groupBy((apt) => apt.date)
      )
    );
  }, [appointments]);
  if (isLoading) {
    return <Loading message="Getting updates..." />;
  }
  return (
    <div className="space-y-4">
      {earlyAccessWindows.length > 0 && (
        <div className="card">
          <h2>Schedule early access</h2>
          {earlyAccessWindows.map((earlyAccessWindow) => {
            return (
              <div key={earlyAccessWindow.id}>
                <p>
                  {earlyAccessWindow.facility.displayName} has a new schedule,
                  and you have early access!
                </p>
                <p>
                  Starting{' '}
                  <span className="font-semibold">
                    {moment(earlyAccessWindow.startDate).format(
                      DATE_FMT.DOW_MONTH_D
                    )}
                  </span>{' '}
                  until end of day{' '}
                  <span className="font-semibold">
                    {moment(earlyAccessWindow.endDate).format(DATE_FMT.MONTH_D)}
                  </span>
                  , you have exclusive access to send proposals to your clients.
                </p>
                <p>
                  The schedule opens for general availability on{' '}
                  {moment(earlyAccessWindow.rollout.publicAccessDate).format(
                    DATE_FMT.DOW_MONTH_D_YEAR
                  )}
                  .
                </p>
              </div>
            );
          })}
          <CardActions>
            <Button
              variant="outlined"
              color="primary"
              to={INSTRUCTOR_ROUTES.SCHEDULE.ROOT}
            >
              Open Calendar
            </Button>
          </CardActions>
        </div>
      )}
      <div className="card">
        <h2>Next-up</h2>
        {futureDates.length === 0 ? (
          <div className="p-4 my-4 text-gray-500 bg-gray-200 rounded-lg">
            No upcoming bookings
          </div>
        ) : (
          <div className="space-y-4">
            {futureDates.slice(0, 3).map(([date, appointments]) => {
              const isToday = moment().isSame(date, 'date');
              const dDays = Math.abs(moment().diff(date, 'days')) + 1;
              const preHeader = isToday
                ? 'Today'
                : dDays === 1
                ? 'Tomorrow'
                : `In ${dDays} ${'day'.pluralize(dDays)}`;
              return (
                <div key={date}>
                  <h6>{preHeader}</h6>
                  <h5>
                    {appointments[0].facility.displayName},{' '}
                    {moment(date).format(DATE_FMT.DOW_MON_D)}
                  </h5>
                  <div className="-mx-6 divide-y divide-gray-300">
                    {appointments.slice(0, 3).map((appointment) => (
                      <Link
                        key={appointment.id}
                        className="block"
                        to={`${SHARED_ROUTES.SCHEDULE.ROOT}?${QueryParams.AppointmentId}=${appointment.id}`}
                      >
                        <ScheduleObject
                          object={{ ...appointment, type: 'APPOINTMENT' }}
                          isClickable={true}
                        />
                      </Link>
                    ))}
                    {appointments.length > 3 && (
                      <div className="px-4 py-2 m-2 text-base italic">{`And ${
                        appointments.length - 3
                      } more booked this day...`}</div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <CardActions>
          <Button
            variant="outlined"
            color="primary"
            to={INSTRUCTOR_ROUTES.SCHEDULE.ROOT}
          >
            Open Calendar
          </Button>
        </CardActions>
      </div>
    </div>
  );
};

const CardHelpButton = ({ onClick }) => (
  <span className="absolute top-0 right-0">
    <button
      onClick={onClick}
      className="text-gray-600 transition-colors duration-150 hover:text-blue-500"
    >
      <HelpIcon width={24} />
    </button>
  </span>
);

const RateKPI = ({
  rate,
  badgeText,
  badgeColor,
  title,
  description,
  onClickHelp,
}) => {
  return (
    <div className="card">
      <div className="mb-4">
        <CardHelpButton onClick={onClickHelp} />
        <span className="text-5xl font-extrabold leading-none">
          {rate === undefined ? '—' : `${rate}%`}
        </span>
        <div>
          <span className={`badge ${badgeColor}`}>{badgeText}</span>
        </div>
      </div>
      <div>
        <h5 className="text-gray-700">{title}</h5>
        <div className="text-sm text-gray-600">{description}</div>
      </div>
    </div>
  );
};

const RecentCancellationRate = ({
  reliability,
  handleClickHelp,
}: {
  reliability: InstructorReliabilityHistory;
  handleClickHelp(): void;
}) => {
  const rate = Number(reliability?.recentCancellationRate) ?? 0;
  let badgeColor = 'green';
  let badgeText = 'Top performer';
  let description = reliability
    ? `${reliability.recentCancellations} out of ${reliability.recentAppointments} in the last 60 days`
    : 'Loading';
  if (rate > INSTRUCTOR_OVERALL_CANCELLATION_RATE_MAX) {
    badgeColor = 'red';
    badgeText = 'Very high';
  } else if (rate > INSTRUCTOR_OVERALL_CANCELLATION_RATE_AVG) {
    badgeColor = 'yellow';
    badgeText = 'Above average';
  } else if (rate > INSTRUCTOR_OVERALL_CANCELLATION_RATE_ELITE) {
    badgeColor = 'blue';
    badgeText = 'Very good';
  }
  if (reliability?.totalAppointments === 0) {
    badgeText = '';
    badgeColor = '';
    description = 'No data';
  }

  return (
    <>
      {!reliability ? (
        <RateKPI
          rate={undefined}
          badgeColor={undefined}
          badgeText={''}
          description={description}
          onClickHelp={handleClickHelp}
          title="Cancellation rate"
        />
      ) : (
        <RateKPI
          rate={Math.round(reliability.recentCancellationRate * 100)}
          badgeColor={badgeColor}
          badgeText={badgeText}
          description={description}
          onClickHelp={handleClickHelp}
          title="Cancellation rate"
        />
      )}
    </>
  );
};

const LateCancellationRateKPI = ({
  reliability,
  handleClickHelp,
}: {
  reliability: InstructorReliabilityHistory;
  handleClickHelp(): void;
}) => {
  const rate = Number(reliability?.lateCancellationRate) ?? 0;
  let badgeColor = 'green';
  let badgeText = 'Top performer';
  let description = reliability
    ? `${reliability.lateCancellations} out of ${reliability.recentAppointments} in the last 60 days`
    : 'Loading';
  if (rate > INSTRUCTOR_LATE_CANCELLATION_RATE_MAX) {
    badgeColor = 'red';
    badgeText = 'Very high';
  } else if (rate > INSTRUCTOR_LATE_CANCELLATION_RATE_AVG) {
    badgeColor = 'yellow';
    badgeText = 'Above average';
  } else if (rate > INSTRUCTOR_LATE_CANCELLATION_RATE_ELITE) {
    badgeColor = 'blue';
    badgeText = 'Very good';
  } else if (reliability?.totalAppointments === 0) {
    badgeText = '';
    badgeColor = '';
    description = 'No data';
  }

  return (
    <>
      {!reliability ? (
        <RateKPI
          rate={undefined}
          badgeColor={undefined}
          badgeText={''}
          description={description}
          onClickHelp={handleClickHelp}
          title="Late cancellation rate"
        />
      ) : (
        <RateKPI
          rate={Math.round(reliability.lateCancellationRate * 100)}
          badgeColor={badgeColor}
          badgeText={badgeText}
          description={description}
          onClickHelp={handleClickHelp}
          title="Late cancellation rate"
        />
      )}
    </>
  );
};

const KPIs = () => {
  const [showCancellationRateModal, setShowCancellationRateModal] =
    useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalType>();
  const reliability = useSelector(getAccountReliability);
  const earnings = useSelector(getAccountEarnings);
  const earningsFetchState = useSelector(getAccountEarningsFetchState);
  const handleCloseModal = () => {
    setShowModal(false);
    setTimeout(() => setModalType(undefined), 500);
  };
  const handleOpenModal = (type: ModalType) => () => {
    setModalType(type);
  };

  const recentCancellationRate =
    Number(reliability?.recentCancellationRate) ?? 0;

  useEffect(() => {
    // This seems unnecessary, but the aim
    // is to ensure that the modalType is
    // always set in the `name` attribute
    // of the Modal, so that tracking events
    // do not have " — undefined" in the modal name
    if (modalType) {
      setShowModal(true);
    }
  }, [modalType]);

  return (
    <>
      <div className="grid gap-6 sm:grid-cols-3">
        <RecentCancellationRate
          reliability={reliability}
          handleClickHelp={() => setShowCancellationRateModal(true)}
        />
        <LateCancellationRateKPI
          reliability={reliability}
          handleClickHelp={() => setShowCancellationRateModal(true)}
        />
        {earningsFetchState === FETCH_STATE.GET ? (
          <div className="card">
            <div className="mb-4">
              <CardHelpButton onClick={handleOpenModal('Hourly earnings')} />
              <span className="text-5xl font-extrabold leading-none">—</span>
            </div>
            <div>
              <h5 className="text-gray-700">Hourly earnings</h5>
            </div>
          </div>
        ) : !earnings || earnings.numPeriods === 0 ? (
          <div className="card">
            <div className="mb-4">
              <CardHelpButton onClick={handleOpenModal('Equipment bonus')} />
              <span className="text-5xl font-extrabold leading-none">$100</span>
            </div>
            <div>
              <h5 className="text-gray-700">Equipment bonus</h5>
              <div className="text-gray-600">
                Get $100 by teaching 5 lessons!
              </div>
            </div>
          </div>
        ) : (
          <div className="card">
            <div className="mb-4">
              <CardHelpButton onClick={handleOpenModal('Hourly earnings')} />
              <span className="text-5xl font-extrabold leading-none">
                {earnings ? earnings.effectiveRate.toCurrency(false) : '—'}
                <span className="font-bold text-md">/hr</span>
              </span>
              <div>
                <span className={`badge green`}>Excellent</span>
              </div>
            </div>
            <div>
              <h5 className="text-gray-700">Hourly earnings</h5>
              {earnings && (
                <div className="text-gray-600">
                  {earnings.netEarnings.toCurrency(false)} over{' '}
                  {earnings.numComplete} lessons taught
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Modal
        name={`Instructor Dashboard — Cancellation rate`}
        open={showCancellationRateModal}
        onClose={() => setShowCancellationRateModal(false)}
        title="Cancellation rate"
        maxWidth="xs"
      >
        <div className="space-y-5">
          <Callout type="info">
            Cancellations can be highly disruptive. We've found that instructors
            who cancel less frequently tend to retain more clients.
          </Callout>
          <div>
            <h4>
              Your overall cancellation rate is{' '}
              {(recentCancellationRate * 100).toFixed(1)}%
            </h4>
            <p>
              Profiles with a cancellation rate more than{' '}
              <strong>
                {(INSTRUCTOR_OVERALL_CANCELLATION_RATE_MAX * 100).toFixed(1)}%
              </strong>{' '}
              may not be recommended and won't show on pool pages.
            </p>
          </div>
          <div>
            <h4>
              Your late cancellation rate is{' '}
              {(reliability?.lateCancellationRate * 100).toFixed(1)}%
            </h4>
            <p>
              When you are responsible to pay for admission, Propel will
              reimburse you if your Late Cancellation Rate is{' '}
              <strong>
                {(INSTRUCTOR_LATE_CANCELLATION_RATE_MAX * 100).toFixed(1)}%
              </strong>{' '}
              or less.
            </p>
          </div>
          <div>
            <h4>How do I lower my cancellation rate?</h4>
            <p>
              The best way to lower your cancellation rate is to teach more
              lessons without cancellations. Rates are calculated based on the
              last {RECENT_CANCELLATION_NUM_DAYS} days of data, so your rate
              will naturally decrease over time if you avoid cancellations.
            </p>
            <p>
              Your cancellation rate can impact your profile rankings,
              recommendations, or visibility on Propel pool pages.
            </p>
          </div>
          <Controls>
            <Button
              variant="flat"
              onClick={() => setShowCancellationRateModal(false)}
            >
              Close
            </Button>
          </Controls>
        </div>
      </Modal>
      <Modal
        name={`Instructor Dashboard — ${modalType}`}
        open={showModal}
        onClose={handleCloseModal}
        title={modalType}
        maxWidth="xs"
      >
        {modalType === 'Hourly earnings' ? (
          <div>
            <p>
              This is the total you've earned after fee deductions divided by
              the number of lessons you actually taught.
            </p>
            <p>
              Gift cards and lesson credit you've chosen to give to clients are
              not included in this total.
            </p>
            <Controls>
              {/* <Button>Learn more</Button> */}
              <Button variant="flat" onClick={handleCloseModal}>
                Close
              </Button>
            </Controls>
          </div>
        ) : modalType === 'Equipment bonus' ? (
          <div>
            <p>
              After you teach your fifth lesson, you will automatically receive
              a $100 equipment bonus!
            </p>
            <p>
              Use it to get some gear, such as a kick board, diving toys, and
              other items for teaching. You should purchase a water thermometer,
              as cold-water reports will be rejected without one.
            </p>
            <p>
              Here's a list of suggested{' '}
              <Link to={EXTERNAL_ROUTES.BLOG.EQUIPMENT_LIST}>
                lesson equipment
              </Link>{' '}
              to get. Ensure you have everything you need to teach your best and
              impress your clients!
            </p>
            <Controls>
              <Button variant="flat" onClick={handleCloseModal}>
                Close
              </Button>
            </Controls>
          </div>
        ) : null}
      </Modal>
    </>
  );
};

export const TaxNotice = () => {
  const [inc, setInc] = useState(0);
  const thisYear = moment().year();
  const lastYear = thisYear - 1;
  const taxInfoLastHidden: number =
    LocalStore.getPreference('taxInfoLastHidden');
  const showTaxNotice = isTaxSeason() && taxInfoLastHidden !== thisYear;
  const handleHide = () => {
    setInc(inc + 1); // this causes a rerender
    LocalStore.setPreference('taxInfoLastHidden', thisYear);
  };
  if (!showTaxNotice) {
    return null;
  }
  return (
    <div className="card">
      <h2 className="mb-4">Tax information</h2>
      <p>
        As a self-employed individual, you will likely need to fill out a T2125
        form in addition to your normal tax forms. To make this process easy for
        you, we provide an{' '}
        <Link to={INSTRUCTOR_ROUTES.EARNINGS.DETAIL(lastYear)} underline>
          Annual Summary
        </Link>{' '}
        that breaks down your revenue and expenses for the year.
      </p>
      <CardActions>
        {/* <Button onClick={handleHide} variant="flat">
          Dismiss
        </Button> */}
        <Button
          onClick={SHOW_HELP.TEACHING.FILING_TAXES}
          variant="flat"
          color="primary"
        >
          Learn more
        </Button>
      </CardActions>
    </div>
  );
};

const DashboardContainer = () => {
  const onboarding = useSelector(getInstructorOnboarding);

  return (
    <Dashboard title="Overview" width="3xl">
      <KPIs />
      <TaxNotice />
      <Overview />
    </Dashboard>
  );
};

export default DashboardContainer;
