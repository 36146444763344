import { FacilityInstructorSerializer } from 'api/Serializers/Facilities';
import Button from 'components/button';
import Callout from 'components/callout';
import Card from 'components/card';
import Controls from 'components/controls';
import FavouriteButton from 'components/favourite-button';
import InstructorCard from 'components/instructor-list-card';
import ProfileLoadingList from 'components/profile-loading-list';
import { FETCH_STATE, QueryParams, UserType } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useQuery from 'hooks/useQuery';
import Section from 'layouts/section';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import {
  getSearchFacility,
  getSearchInstructors,
  getSearchInstructorsFetchState,
  getSearchPreferences,
  getUserType,
} from 'state/selectors';
import { removeSearchPreferences } from 'state/slice/search';

const isBookable = (i: FacilityInstructorSerializer) => i.numBookable > 0;
const isNotBookable = (i: FacilityInstructorSerializer) => !i.numBookable;

/**
 * To modify the ranking of instructors on a facility, it is recommended
 * to change the server's algorithm behind propel_rank, instead of this function
 */
const byNumBookable = (
  i1: FacilityInstructorSerializer,
  i2: FacilityInstructorSerializer
) => i2.numBookable - i1.numBookable;

const InstructorList = () => {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const instructors = useSelector(getSearchInstructors);
  const searchPreferences = useSelector(getSearchPreferences);
  const fetchState = useSelector(getSearchInstructorsFetchState);
  const dateParam = query.get(QueryParams.Date);
  const timeParam = query.get(QueryParams.Time);
  const { pathname } = useLocation();
  const userType = useSelector(getUserType);
  const facility = useSelector(getSearchFacility);
  const isFiltered =
    !!searchPreferences.segment || searchPreferences.times?.length > 0;

  const handleRemoveFilters = () => {
    dispatch(removeSearchPreferences());
  };

  const handleInstructorClick = (
    instructor: FacilityInstructorSerializer,
    listOrder: number
  ) => {
    rudderanalytics.track('Instructor clicked', {
      instructor,
      list_order: listOrder + 1,
    });
  };

  if (fetchState === FETCH_STATE.GET) {
    return <ProfileLoadingList />;
  } else if (!instructors || instructors.length === 0) {
    return (
      <Section>
        {userType === UserType.Client || userType === UserType.Anonymous ? (
          <Card title="No Instructors Found!">
            {(dateParam || timeParam) && (
              <p>
                No instructors were found with those dates/times. Remove your
                filters and try again.
              </p>
            )}
            <p>
              Want to know when instructors add lesson times here? Add this
              location to your favourites and get daily or weekly updates!
            </p>
            <Controls>
              <Button onClick={handleRemoveFilters}>Remove Filters</Button>
              <FavouriteButton
                contentObject={facility}
                contentType={'facility'}
              />
            </Controls>
          </Card>
        ) : null}
      </Section>
    );
  }
  const bookable = instructors?.filter(isBookable);
  const unbookable = instructors?.filter(isNotBookable);
  return (
    <>
      {bookable
        .sort(byNumBookable)
        .map((instructor: FacilityInstructorSerializer, index) => {
          return (
            <div className="w-full" key={instructor.id}>
              <InstructorCard
                to={`${pathname}/${instructor.slug}`}
                onClick={() => handleInstructorClick(instructor, index)}
                showAvailability
                {...instructor}
              />
            </div>
          );
        })}
      {unbookable.length > 0 && (
        <>
          <Callout title="Schedule currently full" type="info">
            These instructors teach at this pool but are fully booked.
          </Callout>
          {unbookable.map((instructor: FacilityInstructorSerializer, index) => {
            return (
              <div className="w-full" key={instructor.id}>
                <InstructorCard
                  to={`${pathname}/${instructor.slug}`}
                  onClick={() => handleInstructorClick(instructor, index)}
                  showAvailability
                  {...instructor}
                />
              </div>
            );
          })}
        </>
      )}
      {isFiltered && (
        <div className="">
          <p>
            Some instructors may not show due to the applied filters.{' '}
            <button className="inline link" onClick={handleRemoveFilters}>
              Remove filters
            </button>
          </p>
        </div>
      )}
    </>
  );
};

export default InstructorList;
