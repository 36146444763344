import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import api from 'api';
import { Event, TimeFrame } from 'api/Serializers/Appointments';
import { ListButton } from 'components/button';
import Loading from 'components/loading';
import { DATE_FMT, UserType } from 'config';
import { CreditCardIcon, DocumentIcon, MoneyIcon } from 'icons';
import moment from 'moment-timezone';
import { enqueueSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SHARED_ROUTES } from 'utils/routing';
import { SectionProps, SECTION_CLASSES } from '../types';

export const ReceiptButton = ({ appointment }: { appointment: Event }) => {
  const [receiptHTML, setReceiptHTML] = useState('');
  const [receiptIsLoading, setReceiptIsLoading] = useState(false);
  const theme = useTheme();
  const isBreakMd = useMediaQuery(theme.breakpoints.down('md'));
  const iframeRef = useRef(null);
  const disabled =
    !appointment.cancelled && appointment.timeFrame === TimeFrame.Outside48;
  return (
    <>
      {receiptIsLoading && (
        <Loading position="fixed" message="Loading receipt..." />
      )}
      <ListButton
        title="Save receipt"
        subtitle={
          isBreakMd
            ? 'Not available on mobile'
            : disabled
            ? `Available on ${moment(appointment.start)
                .subtract(48, 'hours')
                .tz(appointment.timezone)
                .format(DATE_FMT.MON_D_TIME_A)}`
            : null
        }
        icon={<DocumentIcon width={24} />}
        disabled={isBreakMd || disabled}
        onClick={async () => {
          if (receiptHTML) {
            iframeRef.current.contentWindow.print();
            return;
          }
          setReceiptIsLoading(true);
          try {
            const response = await api.appointments.receipt(appointment.id);
            setReceiptHTML(response.data);
          } catch (err) {
            enqueueSnackbar({
              message:
                'Error generating receipt. A report has been sent to Propel.',
              variant: 'error',
            });
            setReceiptIsLoading(false);
          }
        }}
      />
      {receiptHTML && (
        <iframe
          className="hidden"
          ref={iframeRef}
          srcDoc={receiptHTML}
          sandbox="allow-modals allow-same-origin"
          onLoad={(e) => {
            setReceiptIsLoading(false);
            e.currentTarget.contentWindow.print();
          }}
        />
      )}
    </>
  );
};

const PaymentSection = ({ appointment, userType }: SectionProps) => {
  const history = useHistory();

  const canChangePaymentMethod =
    !!appointment.paymentMethod &&
    !appointment.cancelled &&
    appointment.timeFrame === TimeFrame.Outside48;

  console.log(appointment);
  if (userType === UserType.Host) return null;
  else if (userType === UserType.Instructor) {
    return (
      <div className={SECTION_CLASSES}>
        <h4>Payment</h4>
        <div className="labeled-icon">
          <MoneyIcon width={24} />
          <div className="flex justify-between flex-1">
            <span className="text-gray-900">
              {appointment.activity.appointmentNoun} price (CAD)
            </span>
            <span className="text-gray-900">${appointment.receipt.price}</span>
          </div>
        </div>
      </div>
    );
  } else if (userType === UserType.Client) {
    return (
      <div className={SECTION_CLASSES}>
        <h4>Payment</h4>
        {canChangePaymentMethod && (
          <p>
            Your card will be pre-authorized six days prior to the lesson, then
            charged 48 hours before.
          </p>
        )}
        <div>
          {canChangePaymentMethod ? (
            <ListButton
              title={
                !!appointment.paymentMethod
                  ? `${appointment.paymentMethod.brand.capitalize()} ending in ${
                      appointment.paymentMethod.last4
                    }`
                  : 'Prepaid'
              }
              icon={<CreditCardIcon width={24} />}
              onClick={() =>
                history.push(
                  SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'payment')
                )
              }
            />
          ) : (
            <div className="labeled-icon">
              <CreditCardIcon width={24} />
              <span className="text-gray-900">
                {!!appointment.paymentMethod
                  ? `${appointment.paymentMethod.brand.capitalize()} ending in ${
                      appointment.paymentMethod.last4
                    }`
                  : 'Prepaid'}
              </span>
            </div>
          )}
        </div>
        <div className="labeled-icon">
          <MoneyIcon width={24} />
          <div className="flex justify-between flex-1">
            <span className="text-gray-900">
              {appointment.activity.appointmentNoun} price (CAD)
            </span>
            <span className="text-gray-900">${appointment.receipt.price}</span>
          </div>
        </div>
        <div>
          <ReceiptButton appointment={appointment} />
        </div>
      </div>
    );
  }

  return (
    <div className={SECTION_CLASSES}>
      <h4>Payment</h4>
      <p>
        Your card will be pre-authorized six days prior to the lesson, then
        charged 48 hours before.
      </p>
      <div className="space-y-2">
        <div className="labeled-icon">
          <MoneyIcon width={24} />
          <div className="flex justify-between flex-1">
            <span className="text-gray-900">
              {appointment.activity.appointmentNoun} price (CAD)
            </span>
            <span className="text-gray-900">${appointment.receipt.price}</span>
          </div>
        </div>
        <div className="labeled-icon">
          <CreditCardIcon width={24} />
          <span className="text-gray-900">
            {!!appointment.paymentMethod
              ? `${appointment.paymentMethod.brand.capitalize()} ending in ${
                  appointment.paymentMethod.last4
                }`
              : 'Prepaid'}
          </span>
        </div>
        <div>
          <ReceiptButton appointment={appointment} />
        </div>
      </div>
    </div>
  );
};

export default PaymentSection;
