import ExpandMore from '@mui/icons-material/ExpandMore';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Fab } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Avatar from '@mui/material/Avatar';
import Checkbox from '@mui/material/Checkbox';
import api from 'api';
import {
  Details,
  Payout,
  PayoutsListItemInstructor,
} from 'api/Serializers/Payouts';
import Button from 'components/button';
import ButtonCtrlC from 'components/button-copy-text';
import Card from 'components/card';
import Controls from 'components/controls';
import PayoutSummary from 'components/dashboards/earnings/period-summary';
import Loading from 'components/loading';
import { DATE_FMT } from 'config';
import AppointmentDetail from 'features/appointment-detail';
import useSchedulableFacilities from 'hooks/useSchedulableFacilities';
import { RouteProps } from 'models/route';
import moment from 'moment-timezone';
import { ADMIN_ROUTES } from 'pages/account/admin/utils';
import React, { useEffect, useState } from 'react';

const summaryLines = [
  {
    title: 'Marketplace revenues',
    key: 'marketplaceCashReceived',
    display: (val: number) => val.toCurrency(),
    reversed: false,
  },
  {
    title: 'Instructor revenues',
    key: 'instructorTotalPayout',
    display: (val: number) => val.toCurrency(),
    reversed: false,
  },
  {
    title: 'Propel revenues',
    key: 'propelTotalPayout',
    display: (val: number) => val.toCurrency(),
    reversed: false,
  },
  {
    title: 'Host revenues',
    key: 'hostTotalPayout',
    display: (val: number) => val.toCurrency(),
    reversed: false,
  },
  {
    title: 'Propel taxes',
    key: 'propelTaxes',
    display: (val: number) => val.toCurrency(),
    reversed: false,
  },
  {
    title: 'Propel net income',
    key: 'propelNet',
    display: (val: number) => val.toCurrency(),
    reversed: false,
  },
  {
    title: 'Appointments scheduled',
    key: 'numBooked',
    display: (val: number) => String(val),
  },
  {
    title: 'Appointments delivered',
    key: 'numComplete',
    display: (val: number) => String(val),
  },
  {
    title: 'Cancellation rate',
    key: 'cancellationRate',
    display: (val: number) => `${val}%`,
    reversed: true,
  },
  {
    title: 'New bookings during period',
    key: 'numNewBookings',
    display: (val: number) => String(val),
  },
];

const getFacility = (apt, facilities) => ({
  ...apt,
  facility: facilities.find((f) => f.id === apt.facility),
});

const PayoutDetail = ({ payoutId }) => {
  const [payout, setPayout] = useState<Payout>();
  const [instructors, setInstructors] = useState<PayoutsListItemInstructor[]>();
  const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);
  const [invoiceDetail, setInvoice] = useState<Details>();
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string>();
  const [appointmentDetailId, setAppointmentDetailId] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const { facilities, isFacilitiesLoading } = useSchedulableFacilities();
  const getInvoice = (invoiceId) => async (event) => {
    if (selectedInvoiceId === invoiceId) {
      setSelectedInvoiceId(undefined);
      setInvoice(undefined);
    } else {
      setIsLoading(true);
      setSelectedInvoiceId(invoiceId);
      if (invoiceId) {
        const response = await api.payouts.details(payoutId, {
          invoice: invoiceId,
        });
        setInvoice({
          ...response.data,
          appointments: response.data.appointments.map((apt) =>
            getFacility(apt, facilities)
          ),
        });
      } else {
        setInvoice(undefined);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (payoutId) {
      Promise.all([
        api.payouts.retrieve(payoutId),
        api.payouts.instructors(payoutId),
      ]).then((responses) => {
        setPayout(responses[0].data);
        setInstructors(responses[1].data);
      });
    } else {
      setPayout(undefined);
      setInstructors(undefined);
    }
  }, [payoutId]);

  const handleSelect = (invoiceId: string, allowDeselect = true) => {
    if (
      payout.periodInvoices.find((invoice) => invoice.id === invoiceId).isPaid
    ) {
      return;
    }
    setSelectedInvoices((ids) =>
      ids.indexOf(invoiceId) > -1
        ? allowDeselect
          ? ids.filter((id) => id !== invoiceId)
          : ids
        : [...ids, invoiceId]
    );
  };

  const handleMarkSelectedPaid = async () => {
    setIsLoading(true);
    await api.payouts.paid(payoutId, { invoiceIds: selectedInvoices });
    const newInvoices = [...payout.periodInvoices];
    selectedInvoices.map(
      (id) => (newInvoices.find((invoice) => invoice.id === id).isPaid = true)
    );
    const newPayout = {
      ...payout,
      periodInvoices: newInvoices,
    };
    setPayout(newPayout);
    setSelectedInvoices([]);
    setIsLoading(false);
  };

  if (!payoutId) {
    return null;
  } else if (!payout || !instructors) {
    return <Loading />;
  }

  return (
    <Card maxWidth="4xl" name={payout.start} space="tight">
      {isLoading && <Loading />}
      <h3 className="my-0 font-light font-body">{`${moment(payout.start)
        .utc()
        .format(DATE_FMT.MONTH_D)} - ${moment(payout.end)
        .utc()
        .format(DATE_FMT.MONTH_D)}`}</h3>
      <h6>
        Payout on {moment(payout.end).add(1, 'week').format(DATE_FMT.MONTH_D)}
      </h6>
      <div className="bg-white">
        <div className="no-print">
          {summaryLines.map((summary, i) => (
            <div key={`summary-${i}`} className={`p-2 even:bg-gray-100`}>
              <div className="inline-flex items-center justify-start w-7/12">
                {summary.title}
              </div>
              <div className={'inline-flex justify-end items-center w-5/12'}>
                {summary.display(payout[summary.key])}
              </div>
            </div>
          ))}
        </div>
        <div className="sticky z-10 -mt-px -translate-y-px bg-white shadow top-16 -mx-card-tight">
          <div className="grid items-center justify-between grid-cols-2 gap-4 p-4">
            <div className="flex items-center justify-between font-medium">
              <span>Running total:</span>
              <span className="px-4 py-2.5 rounded-full bg-background">
                {payout.periodInvoices
                  .reduce(
                    (agg, inv, i) =>
                      selectedInvoices.indexOf(inv.id) > -1
                        ? agg + Number(inv.total)
                        : agg,
                    0
                  )
                  .toCurrency()}
              </span>
            </div>
            <div className="flex justify-end space-x-3">
              <Button
                variant="outlined"
                to={ADMIN_ROUTES.PAYOUTS.nav('marketplace')}
              >
                Go back
              </Button>
              <Button
                color="primary"
                onClick={handleMarkSelectedPaid}
                variant="contained"
              >
                Mark selected as paid
              </Button>
            </div>
          </div>
        </div>
        <div className="-mx-card-tight">
          <table className="w-full my-6">
            <thead>
              <tr className="relative shadow no-print">
                <td>{''}</td>
                <td>
                  <div>Instructor</div>
                </td>
                <td>
                  <div>Appointments</div>
                </td>
                <td>
                  <div>Adjustments</div>
                </td>
                <td>
                  <div>Payout</div>
                </td>
                <td colSpan={2}>
                  <div>Paid</div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{''}</td>
              </tr>
              {payout.periodInvoices
                .map((invoice, i) => {
                  const selected = selectedInvoices.indexOf(invoice.id) > -1;
                  const instructor = instructors.find(
                    (instr) => instr.id === invoice.entity
                  );
                  return { ...invoice, instructor, selected };
                })
                .sort((a, b) =>
                  a.instructor.fullName < b.instructor.fullName ? -1 : 1
                )
                .sort((a, b) =>
                  a.isPaid && !b.isPaid ? 1 : !a.isPaid && b.isPaid ? -1 : 0
                )
                .map((invoice, i) => {
                  const { selected, instructor } = invoice;
                  return (
                    <React.Fragment key={`invoice-${i}`}>
                      <tr
                        className={`relative ${
                          selected
                            ? 'bg-blue-100 text-blue-500'
                            : invoice.isPaid
                            ? 'bg-green-50 text-green-700'
                            : 'text-gray-700'
                        }`}
                      >
                        <td>
                          <Checkbox
                            color="primary"
                            disabled={invoice.isPaid}
                            checked={selected || invoice.isPaid}
                            onChange={() => handleSelect(invoice.id)}
                          />
                        </td>
                        <td className="">
                          <div className="flex items-center">
                            <Avatar src={instructor.avatar} className="mr-2" />
                            <ButtonCtrlC
                              color="default"
                              variant="text"
                              fullWidth={true}
                              copyText={instructor.fullName}
                              onClick={() => handleSelect(invoice.id, false)}
                            >
                              {instructor.fullName}
                            </ButtonCtrlC>
                          </div>
                        </td>
                        <td className="">
                          <div
                            className={`mx-auto avatar shadow ${
                              selected
                                ? 'bg-blue-500 text-blue-100'
                                : invoice.isPaid
                                ? 'bg-green-600 text-green-50'
                                : 'text-gray-600 bg-gray-200'
                            }`}
                          >
                            {invoice.numComplete}
                          </div>
                        </td>
                        <td className="text-center">
                          {invoice.adjustment.toCurrency()}
                        </td>
                        <td>
                          <div className="text-center">
                            <ButtonCtrlC
                              color="default"
                              variant="text"
                              fullWidth={true}
                              copyText={invoice.total.toString()}
                              onClick={() => handleSelect(invoice.id, false)}
                            >
                              {invoice.total.toCurrency()}
                            </ButtonCtrlC>
                          </div>
                        </td>
                        <td>
                          <div className="text-center">
                            {invoice.amountPaid.toCurrency()}
                          </div>
                        </td>
                        <td>
                          <div className="text-center">
                            <Fab onClick={getInvoice(invoice.id)} size="small">
                              <ExpandMore
                                style={
                                  selectedInvoiceId === invoice.id
                                    ? { transform: 'rotate(180deg)' }
                                    : {}
                                }
                                className="transition-transform duration-150"
                              />
                            </Fab>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={6}>
                          <Accordion
                            key={`invoice-${i}`}
                            elevation={0}
                            className={
                              selectedInvoiceId !== invoice.id ? 'no-print' : ''
                            }
                            onChange={getInvoice(
                              selectedInvoiceId === invoice.id
                                ? undefined
                                : invoice.id
                            )}
                            expanded={selectedInvoiceId === invoice.id}
                          >
                            <AccordionDetails className="bg-white">
                              {selectedInvoiceId === invoice.id &&
                                safe(invoiceDetail) && (
                                  <div className="mx-auto">
                                    <PayoutSummary
                                      details={invoiceDetail}
                                      onClickAppointment={(id) => () =>
                                        setAppointmentDetailId(id)}
                                    />
                                  </div>
                                )}
                            </AccordionDetails>
                          </Accordion>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
            </tbody>
          </table>
        </div>
        <Controls>
          <Button
            variant="outlined"
            to={ADMIN_ROUTES.PAYOUTS.nav('marketplace')}
          >
            Go back
          </Button>
          <Button
            color="primary"
            onClick={handleMarkSelectedPaid}
            variant="contained"
          >
            Mark selected as paid
          </Button>
        </Controls>
      </div>
      <AppointmentDetail
        id={appointmentDetailId}
        onClose={() => setAppointmentDetailId(undefined)}
      />
    </Card>
  );
};

const trendStyle = (value, compare, reversed) => {
  const diff = !reversed ? value - compare : compare - value;
  const perc = Math.round((diff / compare) * 100);
  if (perc === 0) {
    return 'text-gray-600';
  } else if (diff > 0) {
    return 'text-green-600';
  } else {
    return 'text-red-600';
  }
};

const SummaryLineHeader = () => (
  <div className="">
    <div className="inline-flex items-center justify-start w-5/12 font-body">
      <h5>Metric</h5>
    </div>
    <div className="inline-flex items-center justify-end w-3/12 font-body">
      <h5>Value</h5>
    </div>
    <div className="inline-flex items-center justify-end w-2/12 font-body">
      <h5>% Prev</h5>
    </div>
    <div className="inline-flex items-center justify-end w-2/12 font-body">
      <h5>% YoY</h5>
    </div>
  </div>
);

const SummaryLine = ({
  title,
  value,
  lastPeriod,
  lastYear,
  display = (val) => String(val),
  reversed = false,
}: {
  title: string;
  value: number;
  lastPeriod?: number;
  lastYear?: number;
  display?: (val: number) => string;
  reversed?: boolean;
}) => {
  return (
    <React.Fragment>
      <div className="inline-flex items-center justify-start w-5/12">
        {title}
      </div>
      <div className={'inline-flex justify-end items-center w-3/12'}>
        {display(value)}
      </div>
      {lastPeriod && lastPeriod > 0 ? (
        <div
          className={`${'inline-flex justify-end items-center w-2/12'} ${trendStyle(
            value,
            lastPeriod,
            reversed
          )}`}
        >
          {Math.round(((value - lastPeriod) / lastPeriod) * 100)}%
          {Math.round(value) > Math.round(lastPeriod) ? (
            <TrendingUpIcon />
          ) : (
            <TrendingDownIcon />
          )}
        </div>
      ) : (
        <div className="inline-flex items-center justify-end w-2/12 text-gray-500">
          n/a
        </div>
      )}
      {lastYear && lastYear > 0 ? (
        <div
          className={`${'inline-flex justify-end items-center w-2/12'} ${trendStyle(
            value,
            lastYear,
            reversed
          )}`}
        >
          {Math.round(((value - lastYear) / lastYear) * 100)}%
          {Math.round(value) >= Math.round(lastYear) ? (
            <TrendingUpIcon />
          ) : (
            <TrendingDownIcon />
          )}
        </div>
      ) : (
        <div className="inline-flex items-center justify-end w-2/12 text-gray-500">
          n/a
        </div>
      )}
    </React.Fragment>
  );
};

const PayoutsList = () => {
  const [payouts, setPayouts] = useState<Payout[]>();
  useEffect(() => {
    const getPayouts = async () => {
      const response = await api.payouts.list();
      setPayouts(response.data);
    };
    getPayouts();
  }, []);
  if (!payouts) {
    return <Loading />;
  }
  return (
    <div className="my-8">
      {payouts.map((payout, i: number) => {
        const lastPeriod = payouts[i + 1 < payouts.length ? i + 1 : i];
        const lastYear = payouts[i + 26 < payouts.length ? i + 26 : i];
        return (
          <div key={`payouts-${i}`} className="my-6">
            <Card
              name={payout.start}
              title={`${moment(payout.start)
                .utc()
                .format(DATE_FMT.MON_D)} - ${moment(payout.end)
                .utc()
                .format(DATE_FMT.MON_D)}`}
              maxWidth="4xl"
              space="tight"
            >
              <div className="p-6 bg-white">
                <SummaryLineHeader />
                {summaryLines.map((summary, k) => (
                  <SummaryLine
                    key={`summary-${k}`}
                    title={summary.title}
                    value={payout[summary.key]}
                    lastPeriod={lastPeriod[summary.key]}
                    lastYear={lastYear[summary.key]}
                    display={summary.display}
                    reversed={summary.reversed}
                  />
                ))}
                <div
                  className={`my-2 font-bold p-4 border ${
                    payout.numUnpaidInstructors === 0
                      ? 'bg-green-200 border-green-500'
                      : 'bg-red-200 border-red-500'
                  }`}
                >
                  <span className="inline-flex items-center justify-start w-3/4">
                    Instructor payouts remaining
                  </span>
                  <span
                    className={'inline-flex justify-end items-center w-1/4'}
                  >
                    {payout.numUnpaidInstructors}
                  </span>
                </div>
                <div>
                  <div className="flex justify-end py-4">
                    <Button
                      to={`${ADMIN_ROUTES.PAYOUTS.nav('marketplace')}/${
                        payout.id
                      }`}
                      variant="contained"
                      color="primary"
                    >
                      View
                    </Button>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        );
      })}
    </div>
  );
};

const MarketplacePayouts: React.FC<RouteProps> = (props) => {
  const { payoutId } = props.match.params;
  return (
    <div>
      {payoutId ? <PayoutDetail payoutId={payoutId} /> : <PayoutsList />}
    </div>
  );
};

export default MarketplacePayouts;
