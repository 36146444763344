import { AxiosResponse } from 'axios';
import { Media } from '../Media';

export type FacilityAssetType = 'Pool';
export type FacilityCategory =
  | 'Hotel'
  | 'Fitness Club'
  | 'Resort'
  | 'Condominium'
  | 'Apartment'
  | 'Community Center'
  | 'Residential'
  | 'System';

export type TemperatureRange = '25-27' | '28-29' | '30-31' | '32-33';

export const PoolTemperature = {
  Cold: '25-27',
  Cool: '28-29',
  Warm: '30-31',
  Hot: '32-33',
} as const;

export const getTemperatureLabel = (temperature: TemperatureRange) =>
  temperature !== undefined
    ? Object.keys(PoolTemperature).find(
        (key) => PoolTemperature[key] === temperature
      ) ?? ''
    : '';

export enum FacilityStatus {
  Incomplete = 'Incomplete',
  Created = 'Created',
  Seeding = 'Seeding',
  Active = 'Active',
  TempClosed = 'TempClosed',
  Closed = 'Closed',
  Unlisted = 'Unlisted',
}

export interface FacilityInstructorSerializer {
  id: number;
  appointmentProductId?: string;
  slug: string;
  avatar: string;
  displayName: string;
  shortDescription: string;
  basePrice: string;
  specialization1: string;
  specialization2: string;
  segments: string[];
  numRecommended: number;
  numBookable: number;
  numDelivered: number;
  sunday: number;
  monday: number;
  tuesday: number;
  wednesday: number;
  thursday: number;
  friday: number;
  saturday: number;
}

export interface FacilityGoogleReviewSerializer {
  id: string;
  rating: number;
  created: string;
  comment: string;
  reviewerName: string;
  reviewerAvatar: string;
}

export interface DaySerializer {
  open: string;
  close: string;
  duration?: number;
}

export interface FacilityWeekdayScheduleSerializer {
  sunday: DaySerializer;
  monday: DaySerializer;
  tuesday: DaySerializer;
  wednesday: DaySerializer;
  thursday: DaySerializer;
  friday: DaySerializer;
  saturday: DaySerializer;
}

export interface FacilityScheduleSerializer
  extends FacilityWeekdayScheduleSerializer {
  startDate: string;
  endDate: string;
  publicAccessDate: string;
  exceptions: string[];
}

export interface FacilityAmenitySerializer {
  id: number;
  description: string;
  title: string;
  iconUrl: string;
  value: number;
}

export interface FacilityRuleSerializer {
  title: string;
}

export interface PoolAttributeSerializer {
  subtype: string;
  iconUrl: string;
  isIndoor: boolean;
  isHeated: boolean;
  hasWashroomAccess: boolean;
  maxLength: string;
  maxDepth: string;
  minDepth: string;
  temperature: TemperatureRange;
  lifeguardOnDuty: boolean;
  dateCreated: string;
  nlsRequired: boolean;
}

export interface LatLng {
  lat: number;
  lng: number;
}

export interface FacilityRegionSerializer {
  city: string;
  distance: number;
  label: string;
  latlng: LatLng;
  regionCode: string;
  slug: string;
  timezone: string;
  countryCode?: string;
  line1?: string;
  postalCode?: string;
}

export interface FacilityListItemSerializer {
  id: number;
  admissionFee: number;
  amenities: FacilityAmenitySerializer[];
  avatar: string;
  displayName: string;
  hero: string;
  numInstructors: number;
  region: FacilityRegionSerializer;
  shortDescription: string;
  slug: string;
  status: FacilityStatus;
  near: string;
  category: FacilityCategory;
  subType: string;
  assetType: FacilityAssetType;
  hasFreeParking: boolean;
}

export interface FacilitySchedulableSerializer {
  admissionFee: number;
  amenities: FacilityAmenitySerializer[];
  assetType: string;
  attributes: PoolAttributeSerializer;
  avatar: string;
  canSubmitSchedule: boolean;
  category: FacilityCategory;
  displayName: string;
  hasFreeParking: boolean;
  hero: string;
  id: number;
  instructorNotes?: string;
  // media: Media[];
  near: string;
  numInstructors: number;
  // rank: AppointmentProductStatus;
  region: FacilityRegionSerializer;
  shortDescription: string;
  shortName: string;
  slug: string;
  status: FacilityStatus;
  subType: string;
}

export interface FacilityDetailSerializer extends FacilityListItemSerializer {
  attributes: PoolAttributeSerializer;
  description: string;
  rules: FacilityRuleSerializer[];
  media: Media[];
  parkingAndAccess: string;
  shortAddress: string;
}

export interface FacilityLimiterSerializer {
  id: string;
  start: string;
  end: string;
  date: string;
  time: string;
  timezone: string;
}

export interface Api {
  list: (params?: {
    region: string;
  }) => Promise<AxiosResponse<FacilityDetailSerializer[]>>;
  detail: (slug: string) => Promise<AxiosResponse<FacilityDetailSerializer>>;
  limiters: (
    slug: string,
    params: { start: string; end: string }
  ) => Promise<AxiosResponse<FacilityLimiterSerializer[]>>;
  schedules: (
    slug: string,
    params: { start: string; end: string }
  ) => Promise<AxiosResponse<FacilityScheduleSerializer[]>>;
  schedulable: (
    params?: { username?: string; forceNew?: 'true' },
    cancelToken?
  ) => Promise<AxiosResponse<FacilitySchedulableSerializer[]>>;
  googleReviews: (
    slug: string,
    params?: { minRating?: number; limit?: number; offset?: number }
  ) => Promise<
    AxiosResponse<{ count: number; reviews: FacilityGoogleReviewSerializer[] }>
  >;
}
