// const pre = str => typeof str === 'undefined' || str === '' ? '' : str.charAt(0) === '/' ? str : `/${str}`;
import { IS_SERVER, QueryParams } from 'config';
import { BookingStep } from 'features/booking-flow';
import { UTMData } from 'models/route';
import { LocalStore } from 'state/storage';

export enum LocationPriceAction {
  Info = 'info',
  SetPrice = 'set-price',
  Fees = 'fees',
  Complete = 'complete',
  Delete = 'delete',
  Review = 'review',
}

export const APP_ROUTES = {
  HOME: '/',
  BOOK: {
    PATH: '/book/:appointmentProductId/:step?',
    ROOT: '/book',
    nav: (aptProdId: string, step?: BookingStep) =>
      `/book/${aptProdId}${!step ? '' : `/${step}`}`,
  },
  PRICING: '/pricing',
  HELP: '/help',
  ABOUT_US: '/about-us',
  FEEDBACK: {
    AVAILABILITY: '/feedback/availability/:form/:uid',
    APPOINTMENT: '/feedback/appointment/:eventId/:overallRating',
  },
  NOT_FOUND: '/404',
  LEARN_MORE: {
    PATH: '/learn-more/:audience',
    ROOT: '/learn-more',
    INSTRUCTORS: '/learn-more/instructors',
    SWIMMERS: '/learn-more/swimmers',
  },
  STORE: '/store',
  OPPORTUNITIES: {
    PATH: '/pool-opportunities/:facilitySlug',
    nav: (facilitySlug: string) => `/pool-opportunities/${facilitySlug}`,
  },
  BLOG: '/blog',
  BROWSE_CITIES: '/locations',
  BROWSE: '/locations/:regionSlug?/:facilitySlug?/:instructorSlug?',
  BROWSE_CITY: (regionSlug?) => {
    const isSafe = !!regionSlug && regionSlug !== '';
    const isLocalSafe =
      !!LocalStore.get('regionSlug') && LocalStore.get('regionSlug') !== '';
    return isSafe
      ? `/locations/${regionSlug}`
      : isLocalSafe
      ? `/locations/${LocalStore.get('regionSlug')}`
      : '/locations';
  },
  BROWSE_FACILITY: (regionSlug: string, facilitySlug: string) =>
    `/locations/${regionSlug}/${facilitySlug}`,
  BROWSE_INSTRUCTOR: (
    regionSlug: string,
    facilitySlug: string,
    instructorSlug: string
  ) => `/locations/${regionSlug}/${facilitySlug}/${instructorSlug}`,
  FACILITY_LIST: '/locations/:regionSlug',
  FACILITY_DETAIL: '/locations/:regionSlug/:facilitySlug',
  GIFT_CARDS: {
    ROOT: '/gift-cards',
    BUY: {
      ROOT: '/gift-cards/buy',
      PATH: '/gift-cards/buy/:step',
      nav: (step: 'info' | 'checkout') => `/gift-cards/buy/${step}`,
    },
    REDEEM: '/gift-cards/redeem',
  },
  INSTRUCTOR_LIST: '/locations/:regionSlug/:facilitySlug',
  INSTRUCTOR_DETAIL:
    '/locations/:regionSlug/:facilitySlug/:instructorSlug?/:action?',
  INSTRUCTOR_SHORTCODE: {
    link: (slug: string) => `/${slug}`,
  },
  INSTRUCTOR_REVIEW: {
    PATH: '/instructor-review/:appointmentId?/:action?/',
    BASE: (appointmentId: string, key: string) =>
      `/instructor-review/${appointmentId}?key=${key}`,
    COMPLETE: (appointmentId: string, key: string) =>
      `/instructor-review/${appointmentId}/complete?key=${key}`,
  },
  LOGIN: '/login',
  LOGOUT: '/logout',
  REQUEST_NEW_PASSWORD: '/password-reset',
  SETUP_NEW_ACCOUNT: '/password-setup',
  SET_NEW_PASSWORD: '/password-reset/:uid',
  SIGN_UP: '/sign-up',
  NEW_FACILITY: '/new-facility',
  NEW_FACILITY_PATH: '/new-facility/:step?',
  ACCOUNT: '/account',
  ACCOUNT_PATH: '/account/:view?/:mode?/:action?/:detail?',
  TERMS_OF_SERVICE: '/terms-of-service',
  PRIVACY: '/privacy',
};

export const SHARED_ROUTES = {
  DASHBOARD: {
    /** /account */
    PATH: '/account',
    /** /account */
    ROOT: '/account',
  },
  /** @return '/account', */
  ASSISTANCE: {
    PATH: '/account/assistance/:appointmentId?/:action?/:view?',
    ROOT: '/account/assistance',
    nav: (appointmentId: string, view?: string) =>
      `/account/assistance/${appointmentId}${view ? `/${view}` : ''}`,
  },
  /** @return '/account/activate', */
  ACTIVATE: {
    PATH: '/account/activate',
    ROOT: '/account/activate',
  },
  /** @return '/account/schedule */
  SCHEDULE: {
    PATH: '/account/schedule/:mode?/:action?',
    ROOT: '/account/schedule',
    availability: '/account/schedule/availability',
    proposals: '/account/schedule/proposals',
    completeProposals: '/account/schedule/proposals/complete',
    schedules: '/account/schedule/schedules',
    weekly: '/account/schedule/weekly',
    learn: '/account/schedule/learn-availability',
    appointment: (appointmentId: string) =>
      `/account/schedule?${QueryParams.AppointmentId}=${appointmentId}`,
    proposal: (proposalId: string) =>
      `/account/schedule?${QueryParams.ProposalId}=${proposalId}`,
  },
  /** @return '/account/facilities', */
  FACILITIES: {
    PATH: '/account/facilities/:facilitySlug?/:view?',
    ROOT: '/account/facilities',
  },
  /** @return '/account/messages', */
  MESSAGES: {
    PATH: '/account/messages/:userId?',
    ROOT: '/account/messages',
    nav: (userId: string | number) => `/account/messages/${userId}`,
  },
  /** @return '/account/health-and-safety', */
  HEALTH_AND_SAFETY: {
    PATH: '/account/health-and-safety',
    ROOT: '/account/health-and-safety',
  },
  /** @return '/account/settings', */
  SETTINGS: {
    PATH: '/account/settings/:action?',
    ROOT: '/account/settings',
    nav: (action: 'payment') => `/account/settings/${action}`,
  },
  /** @return '/account/terms-of-service/:action?', */
  TERMS_OF_SERVICE: {
    PATH: '/account/terms-of-service/:action?',
    ROOT: '/account/terms-of-service',
    nav: (action?: 'reject' | 'accept') =>
      `/account/terms-of-service${!!action ? `/${action}` : ''}`,
  },
  REVIEWS: {
    PATH: '/account/reviews/:username?',
    ROOT: '/account/reviews',
    nav: (username) => `/account/reviews/${username}`,
  },
  PROPOSALS: {
    PATH: '/account/proposals/:id?/:action?',
    ROOT: '/account/proposals',
    root: '/account/proposals',
    finalize: '/account/proposals/finalize',
    nav: (id?: string, action?: ProposalSubaction) =>
      `/account/proposals${id ? `?id=${id}` : ''}${
        action ? `&action=${action}` : ''
      }`,
  },
};

type ProposalSubaction = 'new' | 'finalize' | 'approve' | 'cancel' | 'archive';

export const HOST_ROUTES = {
  ...SHARED_ROUTES,
  NEW_FACILITY: {
    PATH: '/account/new-facility/:step?',
    ROOT: '/account/new-facility',
  },
};

export const InstructorOnboardingRoutes = {
  /** welcome */
  continue: 'continue',
  S00: 'welcome',
  /** everything-you-need */
  S01: 'everything-you-need',
  /** what-describes-you */
  S02: 'what-describes-you',
  /** how-fees-work */
  S03: 'how-fees-work',
  /** basic-info */
  S04: 'basic-info',
  /** teaching-experience */
  S05: 'teaching-experience',
  /** learn-about-experience */
  S06: 'learn-about-experience',
  /** qualifications */
  S07: 'qualifications',
  /** no-qualifications */
  S08: 'no-qualifications',
  /** client-experience */
  S09: 'client-experience',
  /** unique-experience */
  S10: 'unique-experience',
  /** basic-profile */
  S11: 'basic-profile',
  /** set-teaching-preferences */
  TeachingPreferences: 'set-teaching-preferences',
  /** how-facility-admission-works */
  S13: 'how-facility-admission-works',
  /** browse-facilities */
  S14: 'browse-facilities',
  /** /too-young */
  ErrorTooYoung: 'too-young',
  /** prices */
  SetPrice: 'prices',
};

export const EXTERNAL_ROUTES = {
  BLOG: {
    ROOT: 'https://blog.propelhq.com',
    WEEKEND_HUSTLE: 'https://blog.propelhq.com/priscillas-weekend-hustle',
    WHILE_YOU_WAIT:
      'https://blog.propelhq.com/while-you-wait-for-propel-in-your-city',
    CLIENT_BASE_BUILDING: 'https://blog.propelhq.com/client-base-building-101',
    BECOME_AN_INSTRUCTOR:
      'https://blog.propelhq.com/how-to-become-a-lifeguard-or-swimming-instructor',
    INSTRUCTOR_PERKS: 'https://go.propelhq.com/swim-instructor-perks',
    EQUIPMENT_LIST:
      'https://blog.propelhq.com/essential-equipment-every-swim-instructor-should-have',
  },
  EQUIPMENT_BONUS_SIGNUP: 'https://connect.propelhq.com/equipment',
  HOST_LANDING_PAGES: {
    COMMERCIAL: 'https://heypr.pl/hotels',
    RESIDENTIAL: 'https://heypr.pl/homes',
  },
  DIRECT_DEPOSIT: 'https://go.propelhq.com/direct-deposit',
  INSTRUCTOR_CANCELLATION_POLICY:
    'https://help.propelhq.com/article/show/58792-what-is-propel-s-48-hour-cancellation-and-rescheduling-policy',
  GIFT_CERTIFICATES: 'https://go.propelhq.com/gift-certificates',
  NEWS: {
    TORONTO:
      'https://www.thestar.com/news/gta/2019/08/27/how-hotel-pools-could-be-used-to-trim-wait-lists-for-toronto-swimming-lessons.html',
    GLOBAL: 'https://omny.fm/shows/kelly-cutrara/connecting-people-to-pools',
    CBC: 'https://www.cbc.ca/player/play/1593995843778',
    STARTUP:
      'https://startupheretoronto.com/type/profiles/taking-the-plunge-into-a-new-way-to-deliver-swim-lessons/',
    VANCOUVER:
      'https://vancouversun.com/News/Metro/hundreds-of-metro-vancouver-children-turned-away-from-swimming-lessons',
  },
  COVID19: 'https://go.propelhq.com/helping-our-community',
  INSTRUCTOR_SELF_EMPLOYED_TAXES:
    'https://www.moneysense.ca/save/taxes/file-taxes-when-self-employed/',
  HELP: {
    ROOT: 'https://help.propelhq.com/',
    WHO_CAN_TEACH_WITH_PROPEL:
      'https://help.propelhq.com/article/show/101864-who-can-teach-with-propel',
  },
};

export const SHOW_HELP = {
  ROOT: () => window.HelpWidget.show({ category: 'root' }),
  CONTACT: () => window.HelpWidget.show('contact'),
  FOR_THIS_PAGE: () => window.HelpWidget.show(),
  CANCELLATION_POLICY: () =>
    window.HelpWidget.show({
      article: '58746-what-is-propel-s-cancellation-and-rescheduling-policy',
    }),
  AGES: () =>
    window.HelpWidget.show({
      article: '58688-what-ages-and-ability-levels-are-taught',
    }),
  COST: () =>
    window.HelpWidget.show({ article: '58729-how-much-does-propel-cost' }),
  NEW_POOL_NOTIFICATION: () =>
    window.HelpWidget.show({
      article: '169011-how-can-i-be-notified-when-new-pools-become-available',
    }),
  REGISTER: () =>
    window.HelpWidget.show({
      article: '58697-how-and-when-can-i-book-swimming-lessons',
    }),
  REPORT_CARDS: () =>
    window.HelpWidget.show({ article: '58760-will-i-receive-a-report-card' }),
  NOTIFICATION_LIST: () =>
    window.HelpWidget.show({
      article: '126473-how-can-i-be-notified-when-lessons-are-available',
    }),
  TEACHING: {
    ROOT: () => window.HelpWidget.show({ category: 'teaching-with-propel' }),
    GETTING_BOOKED: () =>
      window.HelpWidget.show({ category: 'getting-booked' }),
    RESCHEDULING: () =>
      window.HelpWidget.show({ category: 'rescheduling-and-cancellations_1' }),
    TEACHING_LESSONS: () =>
      window.HelpWidget.show({ category: 'teaching-lessons' }),
    LESSON_ISSUES: () =>
      window.HelpWidget.show({ category: 'lesson-issues_1' }),
    HOW_IT_WORKS: () =>
      window.HelpWidget.show({
        article: '101863-how-does-teaching-with-propel-work',
      }),
    MINIMUM_TIME_COMMITMENT: () =>
      window.HelpWidget.show({
        article: '109605-is-there-any-minimum-time-commitment',
      }),
    SETTING_AVAILABILITY: () =>
      window.HelpWidget.show({
        article: '58783-how-do-i-set-my-teaching-availability',
      }),
    CANCELLATION_POLICY: () =>
      window.HelpWidget.show({
        article: '58792-what-is-propel-s-cancellation-and-rescheduling-policy',
      }),
    LIABILITY_INSURANCE: () =>
      window.HelpWidget.show({
        article: '101867-what-about-liability-insurance',
      }),
    FILING_TAXES: () =>
      window.HelpWidget.show({
        article: '107052-how-do-i-file-taxes-as-an-instructor-with-propel',
      }),
    PROPOSALS: {
      ROOT: () => window.HelpWidget.show({ category: 'lesson-proposals' }),
      HOW_DO_LESSON_PROPOSALS_WORK: () =>
        window.HelpWidget.show({
          article: '146130-how-do-lesson-proposals-work',
        }),
      WHAT_DOES_STATUS_MEAN: (status: string) => () =>
        window.HelpWidget.show({
          article: `150114-what-does-this-proposal-status-mean#${status}`,
        }),
    },
  },
  HOSTING: {
    ROOT: () => window.HelpWidget.show({ category: 'hosting-with-propel' }),
  },
  LEARNING: {
    ROOT: () => window.HelpWidget.show({ category: 'learning-with-propel' }),
    HOW_DO_LESSON_PROPOSALS_WORK: () =>
      window.HelpWidget.show({
        article: '146147-how-do-lesson-proposals-work',
      }),
    FAVORITES: () =>
      window.HelpWidget.show({
        article: '158855-what-are-favourites',
      }),
  },
  DEFERRED_PAYMENTS: () =>
    window.HelpWidget.show({
      article: '169310-how-does-propel-s-book-now-pay-later-work',
    }),
  PRICING: () =>
    window.HelpWidget.show({ article: '58729-how-much-does-propel-cost' }),
  COVID: {
    GENERAL: () =>
      window.HelpWidget.show({ article: '111612-covid-safe-lessons' }),
    POOL_STATUS: () =>
      window.HelpWidget.show({ article: '112162-which-facilities-are-open' }),
  },
  CALENDAR_SUBSCRIPTION: () =>
    window.HelpWidget.show({
      article:
        '170114-how-do-i-sync-my-lesson-schedule-to-my-device-or-calendar-app',
    }),
  BY_ARTICLE: (article) => () => window.HelpWidget.show({ article }),
  BY_CATEGORY: (category) => () => window.HelpWidget.show({ category }),
};

/**
 * Used for redirection of old routes to new ones
 */
export const DEPRECATED_ROUTES = {
  BROWSE_WITH_INSTRUCTORS_PATH:
    '/locations/:regionSlug/:facilitySlug/instructors/:instructorSlug?',
  ACCOUNT_CALENDAR: '/account/calendar/:mode?',
  INSTRUCTOR_REVIEW: '/instructor-review',
  NEW_INSTRUCTOR: '/new-instructor/:mode?',
  OLD_SIGNUPS: '/sign-up-*',
  BOOK_MORE: '/account/book-more',
};

export const TRANSPARENT_HEADER_ROUTES = [
  '', // HOME location after / is appended to test
  APP_ROUTES.LEARN_MORE.SWIMMERS,
  APP_ROUTES.LEARN_MORE.INSTRUCTORS,
  APP_ROUTES.ABOUT_US,
  APP_ROUTES.STORE,
];

export const getUTMDataFromParams = (paramsObj: URLSearchParams) => {
  if (!paramsObj.get(QueryParams.Source)) {
    return undefined;
  }
  const utmData: UTMData = {
    source: paramsObj.get(QueryParams.Source),
  };
  if (paramsObj.get(QueryParams.Medium)) {
    utmData.medium = paramsObj.get(QueryParams.Medium);
  }
  if (paramsObj.get(QueryParams.Campaign)) {
    utmData.campaign = paramsObj.get(QueryParams.Campaign);
  }
  return utmData;
};

export const getUrlParam = (key: string) => {
  if (!IS_SERVER && location.search && location.search.indexOf('?') > -1) {
    const params = location.search.substring(1).split('&');
    const index = params.findIndex((pair) => pair.indexOf(key) === 0);
    if (index > -1) {
      return decodeURIComponent(params[index].split('=')[1]);
    }
  }
  return undefined;
};

/**
 * Given key-value pair, modify and return the current URL query param string
 * such that it remains the same, while updating {key}={value} to a new value,
 * or removing if value is undefined
 * @param key query parameter name
 * @param value query parameter value
 * @returns new query parameter string for use in Link or <a href> tags, starting with ?
 */
export const setQueryParam = (key: string, value: string) => {
  const validValue = value && value !== '';
  const escapedValue = encodeURIComponent(value);
  if (IS_SERVER) {
    // do not attempt to get location when server side rendering, throws error
    return undefined;
  } else if (location.search === '') {
    // no search params, if value is valid then return key=value
    return validValue ? `?${key}=${escapedValue}` : '';
  } else if (`&${location.search.substring(1)}`.indexOf(`&${key}=`) === -1) {
    // key not present in search params, set it
    return validValue
      ? `${location.search}&${key}=${escapedValue}`
      : location.search;
  } else {
    // key present in search params, (un)set it
    return (
      '?' +
      location.search
        .substring(1)
        .split('&')
        .map((keyval, i) =>
          keyval.indexOf(`${key}=`) === 0
            ? validValue
              ? `${key}=${value}`
              : ''
            : keyval
        )
        .filter((keyval) => keyval !== '')
        .join('&')
    );
  }
};

/**
 * Turn an array of key value pairs into a query param ready string. Values are added FIFO.
 * @param tupArray Array of [key value] pairs for use in URL query parameters
 * @returns ?key0=value0&key2=value2
 */
export const tuplesToParams = (tupArray: Array<[string, string]>) =>
  tupArray
    .reduce(
      (acc, value) =>
        value[0] && value[1] && value[0] !== '' && value[1] !== ''
          ? `${acc}${value[0]}=${value[1]}&`
          : acc,
      '?'
    )
    .slice(0, -1);
