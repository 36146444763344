import Hidden from '@mui/material/Hidden';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Details, InvoiceLineItem } from 'api/Serializers/Payouts';
import Callout from 'components/callout';
import Loading from 'components/loading';
import { DATE_FMT } from 'config';
import ReadMore from 'containers/read-more';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import EarningsHeader from '../header';
import style from './style.module.scss';

interface Props {
  details: Details;
  onClickAppointment?(id: string): () => void;
}

enum MomentMonth {
  January = 0,
  February = 1,
  March = 2,
  April = 3,
  May = 4,
  June = 5,
  July = 6,
  August = 7,
  September = 8,
  October = 9,
  November = 10,
  December = 11,
}

const SummaryRow = ({
  title,
  subtitle,
  total,
  children,
}: {
  title: string;
  subtitle: string;
  total: number;
  children: any;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <section className="flex flex-col justify-between gap-2 sm:flex-row">
      <div className="flex-1 my-4">
        <h5 className="my-0 font-semibold leading-normal text-gray-800">
          {title}
        </h5>
        <span className="text-sm font-light text-gray-800">{subtitle}</span>
      </div>
      <div className="flex-1 p-6 bg-gray-200 rounded-xl">
        <div className="flex justify-between w-full">
          <span className="font-semibold text-gray-800">
            Total {title.toLowerCase()}
          </span>
          <span className="font-semibold text-gray-800 ">
            {total.toCurrency()}
          </span>
        </div>
        <ReadMore initiallyOpen={false} initialHeight={0} isOpen={isOpen}>
          <div className="py-2">
            <hr />
            {children}
          </div>
        </ReadMore>
        <div className="text-right">
          <button
            className="text-sm text-gray-600"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? 'Close' : 'View details'}
          </button>
        </div>
      </div>
    </section>
  );
};

const LineItem = ({
  description,
  total,
  quantity,
}: {
  description: string;
  total: string | number;
  quantity?: number;
}) => {
  return (
    <div className="flex justify-between">
      <span className="font-medium text-gray-800">
        {!isNaN(quantity) && quantity > 0 && `${quantity} `}
        {description}
      </span>
      <span className="font-medium text-gray-800">{total?.toCurrency()}</span>
    </div>
  );
};

const EarningsSummary = (props: Props) => {
  const { details } = props;
  const lineItems = details.lineItems;
  const earnings = lineItems.filter((item) => item.category === 'EARNINGS');
  const deductions = lineItems.filter((item) => item.category === 'DEDUCTIONS');
  const otherPayments = lineItems.filter(
    (item) => item.category === 'OTHER_PAYMENTS'
  );
  const taxCollected = lineItems.filter(
    (item) => item.category === 'TAX_COLLECTED'
  );
  const getTotal = (agg: number, item: InvoiceLineItem, k: number) =>
    agg + Number(item.total);
  const start = moment(details.summary.start, DATE_FMT.DATETIME_FIELD).utc();
  const end = moment(details.summary.end, DATE_FMT.DATETIME_FIELD).utc();
  const depositDate = moment(end).add(7, 'days');
  return (
    <div className="my-8 space-y-8">
      <section>
        <div className="flex flex-col justify-between gap-2 sm:flex-row">
          <div className="flex-1 my-4">
            <h3 className="my-0 text-2xl font-light leading-normal text-gray-900">
              Payout
            </h3>
            <span className="text-base text-gray-800">
              Amount{moment().isBefore(depositDate) ? ' to be' : ''} deposited
              on {depositDate.format(DATE_FMT.MONTH_D_YEAR)}
            </span>
          </div>
          <div className="flex-1 px-6 py-4 bg-blue-500 rounded-2xl">
            <div className="flex items-center justify-between h-full">
              <span className="text-lg font-semibold text-white print:text-gray-900">
                Total
              </span>
              <span className="text-lg font-semibold text-white print:text-gray-900">
                {lineItems.reduce(getTotal, 0).toCurrency()}
              </span>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section>
        <h3 className="my-0 text-2xl font-light leading-normal text-gray-900">
          Overview
        </h3>
        <div className="space-y-5">
          <SummaryRow
            title="Earnings"
            subtitle="Total revenue earned from all paid appointments"
            total={earnings.reduce(getTotal, 0)}
          >
            {earnings.map((item) =>
              item.total != '0.00' ? <LineItem {...item} /> : null
            )}
          </SummaryRow>
          {taxCollected.length > 0 && (
            <SummaryRow
              title="Tax collected"
              subtitle="Amount of tax collected on appointment earnings"
              total={taxCollected.reduce(getTotal, 0)}
            >
              {taxCollected.map((item) =>
                item.total != '0.00' ? <LineItem {...item} /> : null
              )}
            </SummaryRow>
          )}
          <SummaryRow
            title="Deductions"
            subtitle="Deductions from admission, booking fees, and taxes paid"
            total={deductions.reduce(getTotal, 0)}
          >
            {deductions.map((item) =>
              item.total != '0.00' ? (
                <LineItem description={item.description} total={item.total} />
              ) : null
            )}
          </SummaryRow>
          {otherPayments.length > 0 && (
            <SummaryRow
              title="Other"
              subtitle="Adjustments, bonuses, and promotions from Propel, or reimbursements given to clients"
              total={otherPayments.reduce(getTotal, 0)}
            >
              {otherPayments.map((item) =>
                item.total != '0.00' ? <LineItem {...item} /> : null
              )}
            </SummaryRow>
          )}
        </div>
      </section>
    </div>
  );
};

const PeriodSummary = ({ details, onClickAppointment }: Props) => {
  let start: moment.Moment;
  let end: moment.Moment;
  let depositDate: moment.Moment;
  if (details) {
    start = moment(details.summary.start, DATE_FMT.DATETIME_FIELD).utc();
    end = moment(details.summary.end, DATE_FMT.DATETIME_FIELD).utc();
    depositDate = moment(end).add(7, 'days');
  }
  const collectsTax =
    details && Number(details.summary.totalInstructorTaxCollected) > 0;

  const totals = {
    revenue: 0,
    taxCollected: 0,
    charge: 0,
    admission: 0,
    booking: 0,
    feeTax: 0,
    total: 0,
  };

  return (
    <div>
      <EarningsHeader />
      {!details ? (
        <Loading />
      ) : (
        <div>
          <div className="my-12">
            <h3 className="font-extrabold text-center text-gray-800 font-body">
              {details.instructor.fullName}
            </h3>
            <h5 className="font-normal text-center text-gray-700 font-body">
              For appointments delivered {start.format(DATE_FMT.MONTH_D)} to{' '}
              {end.format(DATE_FMT.MONTH_D_YEAR)}
            </h5>
          </div>
          <EarningsSummary details={details} />
          <hr />
          <h3 className="my-0 text-2xl font-light leading-normal text-gray-900">
            Appointment detail
          </h3>
          <ReadMore
            initiallyOpen={false}
            closeBtnText="Close"
            openBtnText="View per appointment detail"
            initialHeight={0}
          >
            <div className={style.payout}>
              <Hidden mdUp={true}>
                <div className="">
                  <Callout title="Some details below are hidden" type="info">
                    <p>
                      To help readability on your device, some fields are
                      hidden. You can view all details by logging into your
                      account from a desktop or laptop.
                    </p>
                  </Callout>
                </div>
              </Hidden>
              <Table padding="none">
                <TableHead>
                  <TableRow className={style.tableColCategories}>
                    <TableCell colSpan={3}>Appointment details</TableCell>
                    <Hidden smDown={true}>
                      <TableCell colSpan={collectsTax ? 3 : 1}>Sales</TableCell>
                      <TableCell colSpan={3}>Deductions</TableCell>
                      <TableCell>Payout</TableCell>
                    </Hidden>
                  </TableRow>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <Hidden smDown={true}>
                      <TableCell>Time</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell align="right">Revenue</TableCell>
                      {collectsTax && <TableCell align="right">Tax</TableCell>}
                      {collectsTax && (
                        <TableCell align="right">Charged</TableCell>
                      )}
                      <TableCell align="right">Admission</TableCell>
                      <TableCell align="right">Booking</TableCell>
                      <TableCell align="right">Tax</TableCell>
                    </Hidden>
                    <Hidden mdUp={true}>
                      <TableCell>Type</TableCell>
                    </Hidden>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {details.appointments.map((appointment) => {
                    const dt = moment(appointment.datetime).tz(
                      appointment.timezone
                    );
                    totals.revenue += Number(appointment.instruction);
                    totals.taxCollected += Number(appointment.taxCollected);
                    totals.charge += Number(appointment.charge);
                    totals.admission += Number(appointment.admissionFee);
                    totals.booking += Number(appointment.bookingFee);
                    totals.feeTax += Number(appointment.taxPaid);
                    totals.total += Number(appointment.instructorPayout);
                    return (
                      <TableRow
                        key={appointment.id}
                        hover={true}
                        className={style.appointment}
                        onClick={
                          safe(onClickAppointment)
                            ? onClickAppointment(appointment.id)
                            : undefined
                        }
                      >
                        <TableCell>{moment(dt).format(DATE_FMT.M_D)}</TableCell>
                        <Hidden smDown={true}>
                          <TableCell>
                            {moment(dt).format(DATE_FMT.TIME_A)}
                          </TableCell>
                          <TableCell className="border-r border-gray-500 border-solid">
                            {appointment.type}
                          </TableCell>
                          {collectsTax ? (
                            <React.Fragment>
                              <TableCell align="right" className="">
                                {appointment.instruction.toCurrency()}
                              </TableCell>
                              <TableCell align="right">
                                {appointment.taxCollected.toCurrency()}
                              </TableCell>
                              <TableCell align="right">
                                {appointment.charge.toCurrency()}
                              </TableCell>
                            </React.Fragment>
                          ) : (
                            <TableCell align="right" className="">
                              {appointment.instruction.toCurrency()}
                            </TableCell>
                          )}
                          <TableCell
                            align="right"
                            className="border-l border-gray-500 border-solid"
                          >
                            ({appointment.admissionFee.toCurrency()})
                          </TableCell>
                          <TableCell align="right">
                            ({appointment.bookingFee.toCurrency()})
                          </TableCell>
                          <TableCell align="right">
                            ({appointment.taxPaid.toCurrency()})
                          </TableCell>
                        </Hidden>
                        <Hidden mdUp={true}>
                          <TableCell>{appointment.type}</TableCell>
                        </Hidden>
                        <TableCell
                          align="right"
                          className="border-l border-gray-500 border-solid"
                        >
                          {appointment.instructorPayout.toCurrency()}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow className={style.periodTotals}>
                    <Hidden smDown={true}>
                      <TableCell colSpan={3} align="right">
                        Totals:
                      </TableCell>
                      <TableCell align="right" className="bg-gray-100">
                        {totals.revenue.toCurrency()}
                      </TableCell>
                      {collectsTax && (
                        <>
                          <TableCell align="right" className="bg-gray-100">
                            {totals.taxCollected.toCurrency()}
                          </TableCell>
                          <TableCell align="right" className="bg-gray-100">
                            {totals.charge.toCurrency()}
                          </TableCell>
                        </>
                      )}
                      <TableCell align="right">
                        ({totals.admission.toCurrency()})
                      </TableCell>
                      <TableCell align="right">
                        ({totals.booking.toCurrency()})
                      </TableCell>
                      <TableCell align="right">
                        ({totals.feeTax.toCurrency()})
                      </TableCell>
                    </Hidden>
                    <Hidden mdUp={true}>
                      <TableCell
                        align="right"
                        colSpan={2}
                      >{`Total S:${details.summary.numSingle}, D:${details.summary.numDouble}`}</TableCell>
                    </Hidden>
                    <TableCell align="right" className="bg-gray-100">
                      {totals.total.toCurrency()}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </ReadMore>
        </div>
      )}
    </div>
  );
};

export default PeriodSummary;
