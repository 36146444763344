import { FacilityInstructorSerializer } from 'api/Serializers/Facilities';
import AvailabilitySelect from 'components/availability-select';
import Avatar from 'components/avatar';
import Link from 'components/link';
import { ArrowForwardIcon, DownIcon, ThumbIcon, UpIcon } from 'icons';
import React, { useState } from 'react';

interface Props extends FacilityInstructorSerializer {
  showAvailability?: boolean;
  to?: string;
  onClick?(): void;
}

const InstructorCard = ({
  avatar,
  basePrice,
  displayName,
  shortDescription,
  numRecommended,
  numDelivered,
  numBookable,
  segments,
  specialization1,
  specialization2,
  showAvailability = false,
  onClick,
  ...rest
}: Props) => {
  const [showTimes, setShowTimes] = useState(false);
  const specializations = [specialization1, specialization2].filter(
    (spec) => !!spec
  );
  const canShowAvailability = showAvailability && numBookable > 0;
  // const getDOWClasses = (dayOfWeek: string) => {
  //   const base = 'leading-none font-medium !px-1.5';
  //   if (rest[dayOfWeek] > 0) {
  //     return `${base} highlight`;
  //   }
  //   return `${base} disabled`;
  // };

  const body = (
    <div onClick={canShowAvailability ? onClick : null}>
      {numRecommended > 2 && (
        <div className="absolute top-1 text-xs right-1 items-center gap-1 flex px-1.5 text-gray-100 bg-gray-900 rounded-full py-1 leading-none">
          <ThumbIcon fontSize="inherit" width={14} fill="#ffffff" />{' '}
          <span>{numRecommended}</span>
        </div>
      )}
      <div className="space-y-3">
        <div className="flex gap-2">
          <div>
            <Avatar src={avatar} diameter={32} variant="rounded" />
          </div>
          <div className="pt-1 space-y-1 grow">
            <h3 className="my-0 font-bold text-gray-900 text-md">
              {displayName}
            </h3>

            <div className="text-sm font-normal text-gray-800">
              <div className="">
                {segments.concat(specializations).join(' · ')}
              </div>
            </div>
          </div>
          <ArrowForwardIcon className="shrink-0" width={24} />
        </div>
        {/* <div> */}
        {/*   <div className="text-sm button-group"> */}
        {/*     <div className={getDOWClasses('sunday')}>Sun</div> */}
        {/*     <div className={getDOWClasses('monday')}>Mon</div> */}
        {/*     <div className={getDOWClasses('tuesday')}>Tue</div> */}
        {/*     <div className={getDOWClasses('wednesday')}>Wed</div> */}
        {/*     <div className={getDOWClasses('thursday')}>Thu</div> */}
        {/*     <div className={getDOWClasses('friday')}>Fri</div> */}
        {/*     <div className={getDOWClasses('saturday')}>Sat</div> */}
        {/*   </div> */}
        {/* </div> */}
        <div className="flex justify-between px-3 text-xs font-normal text-gray-700">
          <div className="">
            <div className="flex justify-center space-x-2 font-bold text-md">
              <span>{numBookable}</span>
            </div>
            <div className="text-xs text-gray-700">Open spots</div>
          </div>
          <div className="">
            <div className="flex justify-center space-x-2 font-bold text-md">
              <span>{numDelivered}</span>
            </div>
            <div className="text-xs text-gray-700">Lessons delivered</div>
          </div>
          <div className="">
            <div className="flex justify-center space-x-2 font-bold text-md">
              ${basePrice}
            </div>
            <div className="text-xs text-gray-700">per lesson</div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <section
      onClick={canShowAvailability ? null : onClick}
      className="flex flex-col gap-3 w-full p-3 overflow-hidden transition-all duration-150 bg-white border border-gray-400 shadow-sm rounded-2xl"
    >
      {safe(rest.to) ? <Link to={rest.to}>{body}</Link> : body}
      {canShowAvailability && (
        <div className="flex flex-col gap-3">
          <hr className="m-0" />
          <div
            className={`flex mb-3 justify-center ${
              showTimes ? 'absolute mx-auto left-0 right-0 top-5' : 'mt-2'
            }`}
          >
            <button
              className="flex link justify-center gap-1 z-50"
              onClick={() => setShowTimes((x) => !x)}
            >
              {showTimes ? 'Hide' : 'Show'} times
              {showTimes ? <UpIcon width={14} /> : <DownIcon width={14} />}
            </button>
          </div>
          {showTimes && (
            <AvailabilitySelect
              appointmentProductId={rest.appointmentProductId}
              truncateDates
              highlightAvailabilityInCart={false}
              source="EXPLORE"
            />
          )}
        </div>
      )}
    </section>
  );
};

// const InstructorCardComponent = ({ to, ...rest }: Props) =>
//   safe(to) ? (
//     <Link to={to}>
//       <InstructorCard {...rest} />
//     </Link>
//   ) : (
//     <InstructorCard {...rest} />
//   );
//
// export default InstructorCardComponent;
export default InstructorCard;
